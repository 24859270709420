require('./src/styles/global.css')

exports.shouldUpdateScroll = ({
    routerProps: { location },
    getSavedScrollPosition,
}) => {
    document.getElementById("___gatsby").scrollIntoView()

    return false
}
  